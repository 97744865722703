import React from 'react'
import Noticias from "../../Data/Noticias.json"
import { CiClock1 } from "react-icons/ci";
import { IoLocationOutline } from "react-icons/io5";
import { motion } from "framer-motion"
import { Link } from 'react-router-dom';
import ChangePageScreen from '../Utils/ChangePageScreen';



function NewsScreen() {
    return (
        <div className='w-full'>
            <section class="text-gray-600 body-font overflow-hidden">
                <div class="container px-5 py-24 mx-auto">
                    <div class="-my-8 divide-y-2 divide-gray-100">

                        {
                            Noticias.map((noticia, index) => (
                                <div class="py-8 flex items-center justify-center flex-wrap md:flex-nowrap gap-10">
                                    <div class="md:w-64 md:mb-0 mb-0 flex-shrink-0 flex flex-col">
                                        <img class=" object-cover object-center  md:h-auto rounded-2xl w-80" src={noticia.imagen} alt="blog" />
                                    </div>
                                    <div class="md:flex-grow lg:flex lg:flex-col lg:gap-2">
                                        <h2 class="text-2xl font-medium text-primary title-font mb-2 lg:mb-0">{noticia.titulo}</h2>
                                        <p class="text-gray-900 text-sm font-bold flex flex-col md:flex-row items-start gap-2">
                                            <span className='flex gap-2'>
                                                <CiClock1 size={20} />  {noticia.fecha}
                                            </span>
                                            <span className='hidden md:block'>
                                                /
                                            </span>
                                            <span className='flex gap-2'>
                                                <IoLocationOutline size={20} /> {noticia.ubicacion}
                                            </span>
                                        </p>
                                        <p class="leading-relaxed lg:w-[70%] mt-4 lg:mt-0">{noticia.descripcion}</p>
                                        <Link to={`/news/${noticia.id}`} >
                                            <motion.a initial={{ marginLeft: 0 }} whileHover={{ marginLeft: 20 }} transition={{ duration: 0.5 }} class="text-primary inline-flex items-center mt-4 lg:mt-0  cursor-pointer">Más información
                                                <svg class="w-4 h-4 " viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                    <path d="M5 12h14"></path>
                                                    <path d="M12 5l7 7-7 7"></path>
                                                </svg>
                                            </motion.a>
                                        </Link>
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                </div>

            </section>
            <ChangePageScreen />
        </div>
    )
}

export default NewsScreen