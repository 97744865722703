import React from 'react'
import QuienesSomosSection from '../Sections/QuienesSomos'
import EquipoSection from '../Sections/Equipo'
import ChangePageScreen from '../Utils/ChangePageScreen'
import AboutUsVisionImg from '../../Assets/AboutUsVisionImg.webp'
import PlanesdeAccion from '../../Data/PlanesdeAccion.json'
import { Link } from 'react-router-dom'
import { cn } from '../../utils/cn'

function AboutUsScreen() {
    return (
        <div className='w-full'>
            <QuienesSomosSection />
            <EquipoSection />
            <section class="text-black body-font">
                <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                        <h1 class="title-font sm:text-4xl text-3xl font-bold text-black">
                            Misión y Visión
                        </h1>
                        <div className='w-20 border-b-4 h-4 border-primary mb-10 '></div>
                        <p class="mb-8 leading-relaxed text-xl">
                            Misión: Promovemos y protegemos la diversidad familiar en Chile, enfocándonos en la educación, apoyo y defensa de los derechos de las familias formadas por gestación subrogada.
                        </p>
                        <p className='leading-relaxed text-xl'>
                            Visión: Buscamos un futuro en el que todas las formas de familia sean reconocidas, aceptadas y protegidas, con la subrogación entendida y regulada como un camino legítimo hacia la creación de un hogar lleno de amor.
                        </p>

                    </div>
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 overflow-hidden h-[30rem]">
                        <img class="object-cover object-center rounded  w-full h-full " alt="hero" src={AboutUsVisionImg} />
                    </div>
                </div>
            </section>

            <section class="text-black body-font bg-[#f9f9f9] ">
                <div class="container mx-auto flex  py-24  flex-col items-center gap-20">

                    {
                        PlanesdeAccion.map((plan, index) => {
                            return (
                                <div className={cn("flex lg:flex-row flex-col items-center justify-between gap-5  p-5 ", {
                                    'lg:flex-row-reverse bg-white rounded-2xl': plan.reverse == "true"
                                })}>
                                    <img src={plan.img} alt={plan.title} className='lg:w-1/3 lg:h-[20rem] h-[20rem] object-cover rounded-2xl' />
                                    <div className='lg:w-1/2  flex items-center lg:items-start flex-col gap-5'>
                                        <h1 class="title-font sm:text-4xl text-3xl font-bold text-black">
                                            {plan.name}
                                        </h1>

                                        <p class="leading-relaxed text-xl text-center lg:text-start">
                                            {plan.description}
                                        </p>
                                        <Link to={'/contact'} className='bg-primary text-white px-4 py-4 rounded-md' >
                                            Saber más
                                        </Link>
                                    </div>

                                </div>
                            )
                        })

                    }

                </div>
            </section >

            <ChangePageScreen />



        </div >
    )
}

export default AboutUsScreen