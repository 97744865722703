import React from 'react'
import Logo from '../../../Assets/Logo-header.webp'
import Menu from '../../../Data/Menu.json'
import { Link, NavLink } from 'react-router-dom'
import { PiFilmScript, PiHandHeart, PiHouseSimple, PiList, PiNewspaper, PiPersonSimple, PiUser } from 'react-icons/pi'

function Navbar() {


    return (
        <>
            <header className="hidden xl:block w-full ">
                <div className='container px-6 py-4 mx-auto'>
                    <div className='flex items-center justify-between'>
                        <img src={Logo} className='w-72' />
                        <ul className=' items-center gap-5 text-2xl hidden md:flex'>
                            {
                                Menu.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            <NavLink to={item.url} className={({ isActive }) =>
                                                [
                                                    isActive ? "bg-primary text-white px-5 py-2 rounded-2xl text-lg transition-all" : " transition-all hover:bg-primary rounded-2xl text-lg hover:text-white px-5 py-2 cursor-pointer",
                                                ].join(" ")
                                            }>{item.name}</NavLink>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </header>


            <div className=' xl:hidden fixed bottom-0 z-50 w-full py-2 bg-white border-t border-[slate-400]'>
                <div className="flex flex-row justify-center gap-5">
                    <NavLink to={'/'} className="flex flex-col items-center justify-center text-primary">
                        <PiHouseSimple className="text-2xl" />
                        <span className="text-xs">Inicio</span>
                    </NavLink>

                    <Link to={'/about'} className="flex flex-col items-center justify-center text-primary">
                        <PiUser className="text-2xl" />
                        <span className="text-xs">Nosotros</span>
                    </Link>

                    <Link to={'/partners'} className="flex flex-col items-center justify-center text-primary">
                        <PiHandHeart className="text-2xl" />
                        <span className="text-xs">Aliados</span>
                    </Link>

                    <Link to={'/news'} className="flex flex-col items-center justify-center text-primary">
                        <PiNewspaper className="text-2xl" />
                        <span className="text-xs">Noticias</span>
                    </Link>

                    <Link to={'/contact'} className="flex flex-col items-center justify-center text-primary">
                        <PiFilmScript className="text-2xl" />
                        <span className="text-xs">Contacto</span>
                    </Link>
                </div>
            </div>

        </>
    )
}

export default Navbar