import React from 'react'
import Equipo from '../../../Data/Equipo.json'
import { motion } from "framer-motion"
import { cn } from '../../../utils/cn'

function EquipoSection() {
    return (
        <section className='text-black bg-[#f9f9f9]  py-10'>
            <div className='flex flex-col' >

                <div>
                    <h3 className='text-3xl  text-center'>EQUIPO DIRECTIVO
                    </h3>
                    <div className='w-20 border-b-4 h-4 border-black mb-10 mx-auto'></div>
                </div>

                {
                    Equipo.map((item, index) => {
                        return (
                            <div key={index} className={item.position == 'right' ?
                                'flex flex-col lg:items-center justify-between gap-5 lg:flex-row md:flex-col '
                                :
                                'flex flex-col lg:items-center justify-between gap-5 lg:flex-row-reverse md:flex-col lg:px-10 2xl:pl-32  '
                            }>
                                <div className='md:w-full h-[400px] md:h-[400px] xl:w-[600px] lg:w-[400px] '>
                                    <img src={item.img} className='object-cover object-top lg:object-top w-full h-full ' />
                                </div>

                                <div className='flex md:flex-row lg:flex-row lg:items-center md:items-start justify-between items-start flex-col lg:w-[60%] xl:w-[50%]  px-10 pb-20 pt-5 '>
                                    <div>
                                        <h1 className='text-2xl font-bold'>{item.name}</h1>
                                        <div className='w-40 border-b-4 h-4 border-primary'>

                                        </div>
                                        <p className='text-lg mb-4'>{item.rol}</p>
                                    </div>
                                    <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.5 }} className={cn('w-3/3 md:w-80', {
                                        'lg:mr-5 md:mr-5': item.position == 'right',
                                    })}>
                                        <p className='italic'>"{item.description}"</p>
                                    </motion.div>
                                </div>

                            </div>
                        )
                    })
                }

            </div>
        </section>
    )
}

export default EquipoSection