import React from 'react'
import ReactPlayer from 'react-player'

function VideoSection() {
    return (
        <section class="text-black  body-font ">
            <div class="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
                <div class="text-center lg:w-2/3 w-full">
                    <h3 class="title-font sm:text-4xl text-3xl mb-4 text-black">
                        VIDEO NUEVAS FAMILIAS
                    </h3>
                    <div className='w-20 border-b-4 h-4 border-primary mb-10 mx-auto'></div>
                    <p class="mb-8 leading-relaxed">
                        Las nuevas familias están hechas con amor
                    </p>

                </div>
                <div className='w-[300px] md:w-full flex items-center justify-center'>
                    <ReactPlayer url={'https://www.youtube.com/watch?v=dxB0JfrOY4o'} />

                </div>
            </div>
        </section>
    )
}

export default VideoSection