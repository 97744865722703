import React from 'react'
import { useParams } from 'react-router-dom'
import Noticias from '../../Data/Noticias.json'
import { Carousel, Skeleton } from 'antd'

function NoticiaDetail() {

    const { id } = useParams()

    const noticia = Noticias.find(noticia => noticia.id == id)

    return (
        <div className='w-full'>
            <div className='container flex flex-col mx-auto py-10 md:gap-20 gap-5 px-5 md:px-0'>
                <div className='flex items-center flex-col justify-center'>
                    <h3 className='text-3xl  text-primary text-center md:w-96'>{noticia.titulo}</h3>
                    <div className='w-40 border-b-4 h-4 border-primary mb-4'></div>
                </div>

                <div className='grid lg:grid-cols-2 grid-cols-1  gap-10'>
                    <div className='flex flex-col lg:px-10  gap-10'>
                        <h2 className='font-bold text-sm text-primary' >
                            {noticia.fecha} / {noticia.ubicacion}
                        </h2>
                        <p className='text-lg font-normal text-black md:w-[70%] '>
                            {
                                noticia.descripcion
                            }
                        </p>

                        <div className='border flex items-center border-black border-1 justify-center p-5 mt-auto'>
                            Lo sentimos el registro de esta noticia no se encuentra disponible
                        </div>
                    </div>
                    <div className='h-[400px]'>
                        <Carousel arrows autoplay>
                            {
                                noticia.extraImg.map((imagen, index) => (
                                    <div>
                                        <img src={imagen} alt className='w-full h-[400px] object-cover' />
                                    </div>
                                ))
                            }
                        </Carousel>
                    </div>


                </div>

            </div>

        </div>
    )
}

export default NoticiaDetail