import React from 'react'
import HomeHeroImg from '../../Assets/Home-HeroImg.webp'
import { Link } from 'react-router-dom'
import ContactForm from '../Sections/ContactForm'
import QuienesSomosSection from '../Sections/QuienesSomos'
import EquipoSection from '../Sections/Equipo'
import ChangePageScreen from '../Utils/ChangePageScreen'
import DonacionesSection from '../Sections/Donaciones'
import ReactPlayer from 'react-player'
import VideoSection from '../Sections/VideoSection'


function HomeScreen() {

    return (
        <div className='w-full'>
            <div className='w-full h-[40vh] lg:h-[70vh] bg-cover bg-center bg-no-repeat relative' style={{ backgroundImage: `url(${HomeHeroImg})` }}>
                <div id='' className='blackFilter absolute w-full h-full bg-gradient-to-r from-black opacity-90 z-10'></div>
                <div class="container z-50 mx-auto flex px-5 py-24 md:flex-row flex-col items-center relative justify-center h-full">
                    <div class="lg:flex-grow md:w-1/2 lg:items-start flex flex-col md:items-center md:text-left mb-16 md:mb-0 items-center text-center">
                        <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">Nuevas Familias</h1>
                        <p class="mb-8 leading-relaxed text-white">Cada historia de amor, es un hogar</p>
                        <div class="flex justify-center">
                            <Link to={'/contact'} class="inline-flex text-white bg-primary border-0 lg:py-4 lg:px-6 px-10 focus:outline-none rounded-2xl text-lg">Hazte socio</Link>
                        </div>
                    </div>
                </div>
            </div>
            <QuienesSomosSection />
            <EquipoSection />
            <VideoSection />
            <DonacionesSection />
            <ContactForm />
            <ChangePageScreen />

        </div>
    )
}

export default HomeScreen